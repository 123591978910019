.now-fresh {
  .hero-carousel-slick {
    position: relative;
  }

  .slick-dots {
    bottom: 6%;
    width: 100%;

    @include at-breakpoint(x-large) {
      right: 44%;
    }

    li {
      width: 16px;
      height: 16px;

      button {
        width: 100%;
        height: 100%;
        background-color: $color-white;
        border: 1px solid $now-brown;
        border-radius: 100%;
        padding: 0;

        &::before {
          display: none; // Hide the default dot
        }
      }

      &.slick-active {
        button {
          background-color: $now-brown;
        }
      }
    }
  }
}
