// Main product slider
.pdp-slick {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 0.6;

  .gather & {
    position: relative;
    height: 100%;

    @include at-breakpoint(large) {
      picture {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: right;
        }
      }
    }
  }

  .now-fresh & {
    position: relative;
    height: 100%;

    @include at-breakpoint(large) {
      picture {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: right;
        }
      }
    }
  }

  .summit & {
    position: relative;
    height: 100%;

    @include at-breakpoint(large) {
      picture {
        height: 100%;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          object-position: right;
        }
      }
    }
  }

  @include at-breakpoint(large) {
    min-height: 512px;

    :global(.now-fresh) & {
      min-height: none;
    }
  }

  .slick-list {
    aspect-ratio: 1/1;

    .gather & {
      @include at-breakpoint(large) {
        aspect-ratio: unset;
        height: 100%;
      }
    }

    .summit & {
      @include at-breakpoint(large) {
        aspect-ratio: unset;
        height: 100%;
      }
    }
  }

  .slick-dots {
    bottom: -32px;

    @include at-breakpoint(medium) {
      bottom: -56px;
    }

    @include at-breakpoint(large) {
      width: 64px;
      bottom: initial;
      top: 55%;
      left: 33px;
      transform: translate(0, -50%);

      .locale-he & {
        left: auto;
        right: 33px;
      }
    }

    li {
      @include at-breakpoint(large) {
        width: 40px;
        height: 40px;
        margin: 12px 0;
      }
    }

    button {
      padding: 0;

      &::before {
        display: none;
      }

      & > div {
        width: 100%;
        height: 100%;

        &::before {
          content: '';
          display: block;
          position: absolute;
          opacity: 1;
          top: 50%;
          left: 50%;
          width: 8px;
          height: 8px;
          transform: translate(-50%, -50%);
          border-radius: 100%;

          @include at-breakpoint(medium) {
            width: 12px;
            height: 12px;
          }
        }
      }

      &:focus {
        outline: none;

        & > div {
          outline: 2px solid $outline;
        }
      }

      @include at-breakpoint(large) {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: $color-black;

        & > div {
          height: 64px;
          width: 64px;
          border-radius: 100%;
          border: 3px solid $color-white;
          overflow: hidden;
          background-color: $color-white;
          transform: translate(-20%, -20%);

          &::before {
            display: none;
          }

          & > picture > img {
            max-width: 106%;
            transform: scale(1.06) translate(-3%, -3%);
          }
        }

        &:focus > div {
          outline: 2px solid $outline;
        }
      }
    }
  }
}

.gather {
  .pdp-slick {
    .slick-dots {
      button {
        & > div:before {
          background-color: $gather-grey;
        }
      }

      .slick-active {
        button {
          & > div {
            border-color: $gather-green;

            &:before {
              background-color: $gather-green;
            }
          }
        }
      }
    }
  }
}

.go-solutions {
  .pdp-slick {
    .slick-dots {
      button {
        & > div::before {
          background-color: $go-gray;
        }
      }

      .slick-active {
        button {
          & > div::before {
            background-color: $color-black;
          }

          @include at-breakpoint(large) {
            & > div {
              border-color: $go-yellow;
            }
          }
        }
      }

      li {
        height: 20px;
        width: 20px;

        @include at-breakpoint(large) {
          height: 64px;
          width: 64px;
        }
      }
    }
  }
}

.now-fresh {
  .pdp-slick {
    .slick-dots {
      width: 100%;
      top: 90%;
      left: 0;
      right: 0;

      @include at-breakpoint(medium) {
        top: 95%;
      }

      li,
      button,
      div {
        width: 16px;
        height: 16px;
        margin: 0 10px;
      }

      div {
        display: none;
      }
      button {
        & > div::before {
          background-color: $color-white;
          border: 1px solid $now-brown;
        }
      }

      picture {
        display: none;
      }

      .slick-active {
        button {
          background-color: $now-brown;
        }
      }
    }
  }
}

.summit {
  .pdp-slick {
    .slick-dots {
      button {
        & > div:before {
          background-color: $summit-grey;
        }
      }

      .slick-active {
        button {
          & > div {
            border-color: $summit-blue;

            &:before {
              background-color: $summit-blue;
            }
          }
        }
      }
    }
  }
}

// Related products slider
.pdp-related-slick {
  @include at-breakpoint(large) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &--num-prods-3 {
    @include at-breakpoint(large) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    height: auto;

    & > div {
      height: 100%;

      & > div {
        height: 100%;
      }
    }
  }

  .slick-dots {
    bottom: -48px;

    @include at-breakpoint(medium) {
      bottom: -56px;
    }

    button {
      padding: 0;

      &:focus {
        outline: 2px solid $outline;
      }

      &::before {
        content: '';
        display: block;
        opacity: 1;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        transform: translate(-50%, -50%);
        border-radius: 100%;

        @include at-breakpoint(medium) {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.go-solutions {
  .pdp-related-slick {
    .slick-dots {
      button {
        &::before {
          background-color: $color-white;
          opacity: 0.25;
        }
      }

      .slick-active {
        button {
          &::before {
            background-color: $color-white;
            opacity: 1;
          }
        }
      }
    }
  }
}

.now-fresh {
  .pdp-related-slick {
    .slick-dots {
      button {
        &::before {
          background-color: $now-light-tan;
        }
      }

      .slick-active {
        button {
          &::before {
            background-color: $now-grey;
          }
        }
      }
    }
  }
}
