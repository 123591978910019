html {
  font-size: 16px;
  background-color: $grey;
}

html,
body {
  height: 100%;
  position: relative;
  text-align: start;
}

.h1,
h1 {
  @include h1(true);
}

.h2,
h2 {
  @include h2(true);
}

.h3,
h3 {
  @include h3(true);
}

.h4,
h4 {
  @include h4(true);
}

.h5,
h5 {
  @include h5(true);
}

p,
.p1 {
  @include p1(true);
}

.p2 {
  @include p2(true);
}

.p3 {
  @include p3(true);
}

b,
.bold {
  @include bold(true);
}

i,
.italic {
  @include italic;
}

u,
.underline {
  @include underline;
}

.eyebrow {
  @include eyebrow(true);
}

.eyebrow-box {
  @include eyebrow-box(true);
}

.gather {
  font-family: 'Merriweather', sans-serif;

  @each $locale, $font-stack in $gather-primary-fonts {
    .locale-#{$locale} & {
      font-family: $font-stack;
    }
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  h1,
  h2,
  h3,
  h4,
  h5 {
    @each $locale, $font-stack in $gather-secondary-fonts {
      .locale-#{$locale} & {
        font-family: $font-stack;
      }
    }

    .locale-ru & {
      line-height: 1.25;
    }
  }

  a {
    color: $gather-green;

    @media (hover: hover) {
      &:hover {
        color: $gather-lighter-green;

        svg {
          path {
            fill: $gather-lighter-green;
          }
        }
      }
    }
  }
}

.go-solutions {
  font-family: Gotham, sans-serif;

  @each $locale, $font-stack in $go-primary-fonts {
    .locale-#{$locale} & {
      font-family: $font-stack;
    }
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-transform: uppercase;

    @each $locale, $font-stack in $go-secondary-fonts {
      .locale-#{$locale} & {
        font-family: $font-stack;
      }
    }

    .locale-ru & {
      line-height: 1.25;
    }
  }

  a {
    color: $color-black;

    @media (hover: hover) {
      &:hover {
        color: $go-yellow;

        svg {
          path {
            fill: $go-yellow;
          }
        }
      }
    }
  }
}

.now-fresh {
  font-family: Almarai, sans-serif;

  @each $locale, $font-stack in $nf-primary-fonts {
    .locale-#{$locale} & {
      font-family: $font-stack;
    }
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Stag, sans-serif;
    font-weight: 400;

    @each $locale, $font-stack in $nf-secondary-fonts {
      .locale-#{$locale} & {
        font-family: $font-stack;
      }
    }

    .locale-ru & {
      line-height: 1.25;
    }
    .locale-fr & {
      line-height: 1.1;
    }
  }

  a {
    color: $now-brown;

    @media (hover: hover) {
      &:hover {
        font-weight: 500;
        color: $now-brown;

        svg {
          path {
            fill: $now-brown;
            stroke: $now-brown;
          }
        }
      }
    }
  }
}

.petcurean {
  font-family: 'museo-sans-rounded', sans-serif;
  font-weight: 300;

  @each $locale, $font-stack in $pc-primary-fonts {
    .locale-#{$locale} & {
      font-family: $font-stack;
    }
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'museo-sans-rounded', sans-serif;
    font-weight: 600;

    @each $locale, $font-stack in $pc-secondary-fonts {
      .locale-#{$locale} & {
        font-family: $font-stack;
      }
    }

    .locale-zh & {
      line-height: 1.25;
    }
  }

  a {
    color: $petcurean-orange;

    @media (hover: hover) {
      &:hover {
        color: $petcurean-light-orange;

        svg {
          path {
            fill: $petcurean-light-orange;
          }
        }
      }
    }
  }
}

.summit {
  font-family: 'Roboto', sans-serif;

  @each $locale, $font-stack in $summit-primary-fonts {
    .locale-#{$locale} & {
      font-family: $font-stack;
    }
  }

  .h1,
  .h2,
  .h3,
  h1,
  h2,
  h3 {
    text-transform: uppercase;

    @each $locale, $font-stack in $summit-secondary-fonts {
      .locale-#{$locale} & {
        font-family: $font-stack;
      }
    }

    .locale-ru & {
      line-height: 1.25;
    }
  }

  .h4,
  .h5,
  h4,
  h5 {
    text-transform: uppercase;

    @each $locale, $font-stack in $summit-tertiary-fonts {
      .locale-#{$locale} & {
        font-family: $font-stack;
      }
    }

    .locale-ru & {
      line-height: 1.25;
    }
  }

  a {
    color: $summit-blue;

    @media (hover: hover) {
      &:hover {
        color: $summit-light-blue;

        svg {
          path {
            fill: $summit-light-blue;
          }
        }
      }
    }
  }
}

.grid-container {
  @include grid-container();
}

.filler-height {
  height: 3000px;
}

body {
  .gather {
    background-color: $gather-cream;
  }

  .go-solutions {
    color: $color-black;
    background-color: $color-white;
  }

  .now-fresh {
    background-color: $now-tan;
  }

  .petcurean {
    background-color: $petcurean-white;
  }

  .summit {
    background-color: $summit-white;
    color: $summit-black;
  }
}

img {
  max-width: 100%;
  height: auto;
}

// Hide recaptcha badge
.grecaptcha-badge {
  display: none !important;
}

input {
  @include p2(true);
}

// Added for image transitions. needed body to have higher priority
body .image-enter-active,
body .image-enter-done {
  opacity: 1;
}
