/*
	Copyright (C) Hoefler & Co.
	This software is the property of Hoefler & Co. (H&Co).
	Your right to access and use this software is subject to the
	applicable License Agreement, or Terms of Service, that exists
	between you and H&Co. If no such agreement exists, you may not
	access or use this software for any purpose.
	This software may only be hosted at the locations specified in
	the applicable License Agreement or Terms of Service, and only
	for the purposes expressly set forth therein. You may not copy,
	modify, convert, create derivative works from or distribute this
	software in any way, or make it accessible to any third party,
	without first obtaining the written permission of H&Co.
	For more information, please visit us at http://typography.com.
*/

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'Gotham';
  src: url('../public/fonts/Gotham/Gotham-Book_Web.woff2') format('woff2');
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'Gotham';
  src: url('../public/fonts/Gotham/Gotham-Bold_Web.woff2') format('woff2');
}

@font-face {
  font-weight: 800;
  font-style: normal;
  font-family: 'Gotham';
  src: url('../public/fonts/Gotham/Gotham-Black_Web.woff2') format('woff2');
}

/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2020 Schwartzco Inc.
        License: 2011-JBAHXS
*/

@font-face {
  font-family: 'Stag';
  src: url('../public/fonts/Stag/Stag-Medium-Web.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Stag';
  src: url('../public/fonts/Stag/Stag-Book-Web.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Stag';
  src: url('../public/fonts/Stag/Stag-Light-Web.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

/*
 *
 * All OpenType features and all extended glyphs have been removed.
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Yellow Design Studio. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2014 Yellow Design Studio
 *
 *
 *
 *
 */

@font-face {
  font-family: 'Veneer';
  font-weight: 400;
  src: url('../public/fonts/Veneer/veneer-webfont.woff') format('woff');
}

/*
  'Bloc Pro Heavy' & 'Bloc Pro Extra Condensed' web font license purchased from Fontshop
  Used for Summit website
*/

@font-face {
  font-family: 'Bloc Pro Heavy';
  font-weight: 400;
  src: url('../public/fonts/BlocPro/Bloc-Pro-Heavy.woff2') format('woff2');
}

@font-face {
  font-family: 'Bloc Pro Extra Condensed';
  font-weight: 400;
  src: url('../public/fonts/BlocPro/Bloc-Pro-Extra-Condensed.woff2') format('woff2');
}
